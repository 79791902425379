import { Component, Vue, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import MetricModal from '@/components/Modal/MetricModal.vue'
import Datepicker from 'vue2-datepicker'
import http from '@/services'
import {
  Matrix,
  Employed,
  MatrixMetric,
  Metric,
  Content,
  Department,
} from '@/Models'
import matrix from '@/store/matrix'
import MciCard from '@/components/Cards/MciCard.vue'
import { VueConstructor } from 'vue'
import { BModalComponent } from 'buefy/types/components'
import menuModule from '@/store/menu'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    MciCard,
    Datepicker,
  },
})
export default class Mci extends Vue {
  data: Metric[] = []
  departments: Department[] = []
  //openEmployedModal = false
  matrix: Matrix | null = new Matrix()
  metric: Metric = new Metric()
  action = 'create'
  date: Date | string = new Date()
  modal: BModalComponent | null = null

  async mounted() {
    this.matrix = matrix.matrix
    await this.getMetrics()
  }

  @Watch('date')
  async filterByDates() {
    await this.filterGetMetrics()
  }

  uuidv4() {
    return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(
      /[018]/g,
      (c: any) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16),
    )
  }

  async addDepartments(departments: any) {
    try {
      const res = await http.post(`panel/department/`, departments)
      this.departments.push(res.data as Department)
      this.$set(this, 'departments', this.departments)
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }

  async getDepartments() {
    try {
      const res = await http.get(`panel/department/`)
      this.departments = res.data as Department[]
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }

  // clearFilter() {
  //   this.date = []
  // }

  convertData(data: []): Metric[] {
    console.log(data, 'HOLASSSS')
    return data.map((x: Metric) => ({
      ...x,
      date: new Date(x.matrix_metric.date + '-01-02'),
      indicator_id: x.indicator?.id,
      employed_id: x.employed?.id,
      matrix_metric_id: x.matrix_metric.id,
      matrix_metric: {
        ...x.matrix_metric,
        activity_id: x.matrix_metric.activity.id,
        matrix_id: x.matrix_metric.matrix.id,
      },
    }))
  }

  async filterGetMetrics() {
    try {
      {
        await this.getMetrics()
        this.data = this.data.filter(
          (x: any) =>
            new Date(x.date)
              .toISOString()
              .split('T')[0]
              .substring(
                0,
                new Date(x.date).toISOString().split('T')[0].indexOf('-'),
              ) ==
            new Date(this.date as string)
              .toISOString()
              .split('T')[0]
              .substring(
                0,
                new Date(x.date).toISOString().split('T')[0].indexOf('-'),
              ),
        )
      }
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }

  async getMetrics() {
    menuModule.setLoding(true)
    try {
      const res = await http.post(`metric/metric/get_metrics/`, {
        matrix: this.matrix?.id,
      })

      this.data = this.convertData(res.data)
      await this.getDepartments()
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
    menuModule.setLoding(false)
  }

  showUpdateMetric(metric: Metric) {
    this.action = 'update'
    this.openModalDevice(metric, metric.matrix_metric.metric_type)
  }
  showAddMetric(metric: Metric) {
    this.action = 'addValue'
    this.openModalDevice(metric, metric.matrix_metric.metric_type)
  }

  async deleteMetric(metric: Metric) {
    try {
      await http.delete(`metric/metric/${metric.id}/`)
      let index = -1
      index = this.data.findIndex((x: Metric) => x.id == metric.id)
      if (index > -1) this.data.splice(index, 1)
      //await this.getMetrics()
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
    await this.getMetrics()
  }

  async updateMetric(metric: Metric) {
    delete metric.date
    delete metric.perspective
    const { department, indicator, matrix_metric, ...newValues } = { ...metric }
    try {
      let index = -1
      const res = await http.put(`metric/metric/${metric.id}/`, newValues)
      await this.getMetrics()
      index = this.data.findIndex((x: Metric) => x.id == metric.id)
      if (index > -1) this.data[index] = res.data as any
      console.log(res.data as any, 'res')
      this.data = this.convertData(this.data as any)
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }

  async createMetric(metric: Metric) {
    delete metric.date
    delete metric.perspective

    try {
      await http.post(`metric/metric/save/`, {
        ...metric,
        employed_id: metric.employed_id,
      })
      await this.getMetrics()
    } catch (error: any) {
      if (error.response.status == 500) {
        this.$buefy.toast.open({
          message: 'Error ya existe la metrica a guardar',
          type: 'is-danger',
        })
      } else if (error.response && error)
        this.$buefy.toast.open({
          message: 'Elemento guia e indicador repetidos',
          type: 'is-danger',
        })
    }
  }

  addMetric(metricType: string) {
    this.action = 'create'
    this.openModalDevice(null, metricType)
  }

  openModalDevice(metric: Metric | null, metricType: string) {
    if (!metric) {
      this.metric = new Metric()
      this.metric.matrix_metric = new MatrixMetric()
      this.metric.matrix_metric.activity = new Content()
      ;(this.metric.matrix_metric.matrix_id = this.matrix?.id),
        (this.metric.matrix_metric.date = new Date())
      this.metric.metric_value_type = metricType
      this.metric.date = new Date()
    } else {
      this.metric = metric
      this.metric.department_id = metric.department.id
    }
    this.modalOpen(
      MetricModal,
      {
        metric: this.metric,
        matrix: this.matrix,
        departments: this.departments,
        action: this.action,
        metricType: metricType,
      },
      {
        'create-edit-metric': metric ? this.updateMetric : this.createMetric,
        'add-department': this.addDepartments,
      },
    )
  }

  modalOpen(component: VueConstructor<Vue>, props: any, events: any) {
    this.modal = this.$buefy.modal.open({
      parent: this,
      component,
      hasModalCard: true,
      trapFocus: true,
      props,
      events,
    })
  }
}
